import { Disclosure} from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import {Link, NavLink} from "react-router-dom";
import Pages from "../../pages/_pages";

function Navbar() {
    const navLinkClasses = "text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium "

    return (
        <Disclosure as="nav" className="bg-white shadow">
            {({ open }) => (
                <>
                    <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
                        <div className="relative flex justify-between h-16">
                            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                                {/* Mobile menu button */}
                                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                    <span className="sr-only">Open main menu</span>
                                    {open ? (
                                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                                    ) : (
                                        <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                                    )}
                                </Disclosure.Button>
                            </div>
                            <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                                <div className="flex-shrink-0 flex items-center">
                                    <Link to={Pages.HOME}>Divergent Patterns</Link>
                                </div>
                                <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                                    <NavLink
                                        to={Pages.HOME}
                                        className={({ isActive }) =>
                                            isActive ? navLinkClasses + "border-indigo-500" : navLinkClasses + "border-transparent"
                                        }
                                    >
                                        Home
                                    </NavLink>
                                    <NavLink
                                        to={Pages.PATTERNS}
                                        className={({ isActive }) =>
                                            isActive ? navLinkClasses + "border-indigo-500" : navLinkClasses + "border-transparent"
                                        }
                                    >
                                        Patterns
                                    </NavLink>
                                    <NavLink
                                        to={Pages.ANTIPATTERNS}
                                        className={({ isActive }) =>
                                            isActive ? navLinkClasses + "border-indigo-500" : navLinkClasses + "border-transparent"
                                        }
                                    >
                                        Antipatterns
                                    </NavLink>
                                    <NavLink
                                        to={Pages.ARTICLES}
                                        className={({ isActive }) =>
                                            isActive ? navLinkClasses + "border-indigo-500" : navLinkClasses + "border-transparent"
                                        }
                                    >
                                        Articles
                                    </NavLink>
                                </div>
                            </div>

                        </div>
                    </div>

                    <Disclosure.Panel className="sm:hidden">
                        <div className="pt-2 pb-4 space-y-1">
                            {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
                            <NavLink to={Pages.HOME} element={Disclosure.Button}
                                as="a"
                                     className={({ isActive }) =>
                                         isActive ? navLinkClasses + "border-indigo-500" : navLinkClasses + "border-transparent"
                                     }
                            >
                                Home
                            </NavLink>
                            <NavLink to={Pages.PATTERNS} element={Disclosure.Button}
                                  as="a"
                                     className={({ isActive }) =>
                                         isActive ? navLinkClasses + "border-indigo-500" : navLinkClasses + "border-transparent"
                                     }
                            >
                                Patterns
                            </NavLink>
                            <NavLink to={Pages.ANTIPATTERNS} element={Disclosure.Button}
                                  as="a"
                                  href="#"
                                  className={({ isActive }) =>
                                      isActive ? navLinkClasses + "border-indigo-500" : navLinkClasses + "border-transparent"
                                  }
                            >
                                Antipatterns
                            </NavLink>
                            <NavLink to={Pages.ARTICLES} element={Disclosure.Button}
                                     as="a"
                                     href="#"
                                     className={({ isActive }) =>
                                         isActive ? navLinkClasses + "border-indigo-500" : navLinkClasses + "border-transparent"
                                     }
                            >
                                Antipatterns
                            </NavLink>
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    );
}

export default Navbar;