import React from 'react';

function Container({children}) {
    return (
        <div className={"px-5 mt-3 max-w-3xl mx-auto"}>
            {children}
        </div>
    );
}

export default Container;