const AskTheExpert = {
        name: "Ask The Expert",
        blurb:"For when you don't have the information you need, or you're trying to organize things",
        type: "reactive/meta",
        triggers: "In the literal context, you're at sea and don't know the information you need.\n" +
            "\n" +
            "In the meta context, you're trying to decide how to organize things.",
        solution: "Literal context:  find someone you trust who knows.  Ask them.\n" +
            "\n" +
            "Meta context:  Things belong where they're most used.  ",
        implementation: "The literal context is too obvious for words, and so does not need further elaboration.  The meta context is more interesting.\n" +
            "\n" +
            "In software, classes should own the information they're most responsible for.  This can lead to some confusing tradeoffs, but when combined with the [Single Purpose Principle](/pattern/SinglePurposePrinciple), it leads directly to having a single source of truth for every piece of data.\n" +
            "\n" +
            "In other contexts, this can look like placing the kitchen knives next to the cutting work surface, the pens on the writing desk, or other organizational tactics.",
        consequences: "",
    };

export default AskTheExpert;