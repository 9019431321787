const ExchangeBrickForJade = {
        name: "Exchange Brick For Jade",
        blurb:"For finding win win opportunities",
        type: "proactive",
        triggers: "This is a strategy for finding win win opportunities.",
        solution: "Often, what is difficult for you is easy for someone else.  The same applies in reverse.  Look for opportunities to give someone else what is easy/inexpensive for you in exchange for something which is easy/inexpensive for them in such a way that each party improves their overall value.",
        implementation: "* Easy for me to bake bread.  Easy for Mom to make chili.  Exchange bread for chili!",
        consequences: "",
    };

export default ExchangeBrickForJade;