const BeTheBatman = {
        name: "Be The Batman",
        blurb: "For when you're martyring yourself",
        symptoms: "\"I'm whatever Gotham needs me to be.\"" +
            "You're pushing yourself too hard- past the point of exhaustion, past even the point of \"reasonable heroics.\"[^1]  If you find yourself in this mode on the regular, rather than in short bursts- and especially if you're doing Be The Batman out of guilt or perceived obligation- it's time to reassess.",
        why_it_sucks: "* You can't keep this up forever\n" +
            "* Nobody expects this of you\n" +
            "* Unless they suck\n" +
            "* This behavior leads to significant self inflicted psychological trauma and lashing out in resentment towards those whom you ostensibly are caring for/protecting",
    };

export default BeTheBatman;

