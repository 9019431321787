import PatternDB from "../../data/PatternDB";
import {ANTIPATTERN_URL_BASE} from "../../pages/_pages";
import {Link} from "react-router-dom";

function RelatedAntiPattern({related}) {
    const pattern = PatternDB.findAntiPatternById(related);
    const url = ANTIPATTERN_URL_BASE + pattern.slug;


    return (
        <div>
            <Link to={url}>{pattern.content.name}</Link>
        </div>
    );
}

export default RelatedAntiPattern;