const TheInbox = {
        name: "The Inbox",
        blurb:"For catching information/ideas/tasks as they come in",
        type: "proactive",
        triggers: "You have things that you don't want to forget, but lack the time to process/categorize properly.",
        solution: "Jot them down in an easily accessed and reviewed spot - your Inbox.  *THEN REVIEW IT REGULARLY.*  Inbox Zero is the goal.",
        implementation: "* A page in your Engineering Notebook\n" +
            "* A file on your computer\n" +
            "* A notes app on one of your devices\n" +
            "* A dedicated email tag, write emails to yourself",
        consequences: "* Pick *ONE* of these\n" +
            "* Review it regularly\n" +
            "* A stale, unreviewed Inbox discourages use and puts you right back to [Trying To Remember Everything](/antipattern/TryingToRememberEverything)",
    };

export default TheInbox;