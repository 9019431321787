const DescribingEmotions = {
    title:  "Describing Emotions",
    content:
        "A lot of times, I don't know the right words for what I'm feeling.  It can make communication difficult, and it can make it hard to process how I'm feeling in difficult moments.  In order to help myself find better ways to express myself, I came up with a list of questions/qualities I can use to describe how I'm feeling:\n" +
        "\n" +
        "\n" +
        "\n" +
        "| Question                                          | Answers                                                      | Notes                          |\n" +
        "| ------------------------------------------------- | ------------------------------------------------------------ | ------------------------------ |\n" +
        "| How much energy does the emotion have?            | High?  Low?  Don't know?                                     | Don't know probably means low. |\n" +
        "| How comfortable and stable is my emotional state? | Stable?  Unstable?                                           |                                |\n" +
        "| How much pressure am I feeling to take action?    | Pressured to act?  Pressured to **NOT** act?  No pressure at all? |                                |\n" +
        "| Is my mood up or down?                            | Up - positive feelings, Down - negative feelings             |                                |\n" +
        "| Are my thoughts directed in or out?               |                                                              |                                |\n" +
        "\n" +
        "Sadness and depressive emotions would look like:\n" +
        "\n" +
        "* Low energy\n" +
        "* Stable emotional state (though maybe not if I'm spiraling out)\n" +
        "* Pressure to inaction\n" +
        "* Mood down\n" +
        "* Thoughts inward\n" +
        "\n" +
        "Anger might look like:\n" +
        "\n" +
        "* High energy emotion\n" +
        "* Probably unstable (though laser focus anger is possible)\n" +
        "* High pressure to act\n" +
        "* Downward mood\n" +
        "* Outward thoughts\n" +
        "\n" +
        "You might need different questions for your emotional exploration, but these might at least give you a starting point."
}

export default DescribingEmotions;