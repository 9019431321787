const Reacquaint = {
        name: "Reacquaint",
        blurb:"For when you've forgotten where you were on a task or project",
        type: "reactive",
        triggers: "You're resisting a project or complex task which has been put aside for so long that you no longer remember where you were, what you were trying to do, or generally what threads to start tugging on.  ",
        solution: "Make a space for yourself to Reacquaint with the situation.  \n" +
            "\n" +
            "Reacquainting is a research task.  The specific research goals are to:\n" +
            "\n" +
            "* Gather whatever documentation exists\n" +
            "* Review the situation with an eye towards what remains incomplete\n" +
            "* Develop a \"[next step](/pattern/NextStep)\"",
        implementation: "As an example, I recently picked up a project that had been lying fallow for a month.  It was very intimidating, so I made a task list to reacquaint myself with the project.  In included:\n" +
            "\n" +
            "* Review the existing documentation, project plan, and feature list\n" +
            "* (Based on my recall), verify the completeness of the pieces I had been working on before I put the project down\n" +
            "* Develop a more detailed task list of what remains to be done in the incomplete phase of the project, so that I can get back into the flow",
        consequences: "This does introduce an interim step into the process, but as a transition it saves time over the just diving in and flailing.  Making space to document your tasks and leave [Breadcrumbs](/pattern/Breadcrumbs) for yourself (or the next person) also makes this transition easier.",
    };
    
export default Reacquaint;