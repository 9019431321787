import {useParams} from "react-router-dom";
import ArticleDB from "../data/ArticleDB";
import Article from "../components/Article";

function ArticlePage() {
    const params = useParams()
    const article = ArticleDB.findArticleBySlug(params.slug)
    return (
        <Article article={article}/>
    );
}

export default ArticlePage;