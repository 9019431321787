import React from 'react';
import SectionName from "./SectionName";
import MarkdownDisplay from "../ui/MarkdownDisplay";

function PatternSection({name, section}) {
    return (
        <div>
            <SectionName name={name}/>
            <MarkdownDisplay>{section.length > 0 ? section : "N/A"}</MarkdownDisplay>
        </div>
    );
}

export default PatternSection;