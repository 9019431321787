const JustFiveMinutes = {
        name: "Just Five Minutes",
        blurb:"For when you don't have a lot of energy",
        type: "reactive",
        triggers: "You're dreading a task that feels too large for the energy you have.",
        solution: "Spend \"Just Five Minutes\" on the task.  One of two things will happen:  either the task will still feel too big for your cope, or you'll pick up momentum.  In the latter case, congratulations, you're unstuck for now.  In the former... at least you got five minutes more work done.",
        implementation: "* If you know what the [Next Step](/pattern/NextStep) is, start there\n" +
            "* Otherwise, figure out the [Next Step](/pattern/NextStep)",
        consequences: "",
    };

export default JustFiveMinutes;