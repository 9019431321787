import React from "react";

function PatternHeader({pattern}) {
    const adjectives = [];

    if (pattern.type && pattern.type.length > 0) {
        adjectives.push(pattern.type);
    }

    return (
        <div className={"mb-3 border-b border-grey-100 pb-1"}>
            <div>
                <span className={"text-3xl font-bold mr-3"}>{pattern.name}</span> {adjectives.length > 0 ? "(" + adjectives.join(", ") + ")" : ""}
            </div>
            <div>
                {pattern.blurb}
            </div>
        </div>
    );
}

export default PatternHeader;