import MarkdownDisplay from "./ui/MarkdownDisplay";
import ArticleHeader from "./article/ArticleHeader";

function Article({article}) {
    return (
        <div>
            <ArticleHeader article={article.content}/>
            <MarkdownDisplay>{article.content.content}</MarkdownDisplay>
        </div>
    );
}

export default Article;