import React from "react";

function PatternHeader({pattern}) {
    return (
        <div className={"mb-3 border-b border-grey-100 pb-1"}>
            <div>
                <span className={"text-3xl font-bold mr-3"}>ANTIPATTERN: {pattern.name}</span>
            </div>
            <div>
                {pattern.blurb}
            </div>
        </div>
    );
}

export default PatternHeader;