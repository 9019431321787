import {antipatterns} from "../data/PatternDB";
import {Link} from "react-router-dom";
import {ANTIPATTERN_URL_BASE} from "./_pages";

function AntipatternListPage() {
    return (
        <div>
            <div>
                <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Antipatterns</h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">Counterproductive patterns of behavior</p>
                </div>
                <div className="mt-5 border-t border-gray-200">
                    <dl className="sm:divide-y sm:divide-gray-200">
                        {antipatterns.map(p => {return(
                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-4 sm:grid-flow-col">
                                <div>
                                    <Link to={ANTIPATTERN_URL_BASE + p.slug} element={"dt"} className="text-sm font-medium text-gray-500">{p.content.name}</Link>
                                </div>
                                <div>
                                    <Link to={ANTIPATTERN_URL_BASE + p.slug} element={"dd"} className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{p.content.blurb}</Link>
                                </div>
                            </div>
                        )
                        })}




                    </dl>
                </div>
            </div>


        </div>
    );
}

export default AntipatternListPage;