const AskGoodQuestions = {
        name: "Ask Good Questions",
        blurb:"For avoiding miscommunications",
        type: "reactive",
        triggers: "* You're in \"research mode\"\n" +
            "* You're literally [Asking The Expert](/pattern/AskTheExpert)\n" +
            "* You suspect you're [Amelia Bedelia'ing](/antipattern/AmeliaBedelia)",
        solution: "* Wind and rewind the thread in your mind to find the point where confusion begins\n" +
            "* Identify your assumptions - assess what you know, how you know it, and what you only think you know\n" +
            "* Assume everyone can be taken at face value and nobody is an idiot\n" +
            "* Be respectful- this is always a goal, but lean into it right now\n" +
            "* If you're asking an expert for guidance, let them know what independent research or other self help steps you've taken up to this point",
        implementation: "* Ask reflecting questions\n" +
            "* \"I think things work like this- what do you think?\"\n" +
            "* \"I think we might be working at cross purposes, can we back up a little bit?\"\n" +
            "* \"I can't make this work, I've tried these things...\" not \"IT'S BROKE.\"",
        consequences: "",
    };

export default AskGoodQuestions;