const RelyOnPack = {
        name: "Rely On Pack",
        blurb:"For when you're overwhelmed",
        type: "reactive",
        triggers: "You're overwhelmed- there's too much for one person to do.",
        solution: "Ask for help from those closest to you- your pack.",
        implementation: "",
        consequences: "",
    };

export default RelyOnPack;