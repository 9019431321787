const InformationTriage = {
        name: "Information Triage",
        blurb:"For processing your Inbox",
        type: "proactive",
        triggers: "Use this when you're processing your [Inbox](/pattern/TheInbox)",
        solution: "Normally there would be a general solution and multiple implementations.  Not here.  Here are the steps for each item in your inbox.",
        implementation: "### Initial Phase\n" +
            "\n" +
            "1:  Does it need action?\n" +
            "        Yes:  Go To Action phase.\n" +
            "        No:  Go to Archive phase.\n" +
            "\n" +
            "### Action Phase\n" +
            "\n" +
            "1:  Is this still relevant?\n" +
            "    Yes:  Proceed. \n" +
            "    No:  Trash it.\n" +
            "\n" +
            "2:  Is this actionable?\n" +
            "    Yes:  Proceed.\n" +
            "    No:  Create a task to make the item actionable and proceed with that task.\n" +
            "\n" +
            "You now have an actionable task.\n" +
            "\n" +
            "3:  Will this task take less than 5 minutes?\n" +
            "    Yes:  DO IT.  (Assuming that's possible right now.  Otherwise....)\n" +
            "    No:  Proceed.\n" +
            "\n" +
            "4:  Can someone else do it?\n" +
            "    Yes:  Delegate it and create a task to followup at a specific date and time.  Proceed with that task.\n" +
            "    No:  Proceed.\n" +
            "\n" +
            "5:  Does it need to be done on a specific date/at a specific time?\n" +
            "    Yes:  Put it on your calendar.\n" +
            "    No:  Proceed.\n" +
            "\n" +
            "6:  Is it part of a specific project?\n" +
            "    Yes:  Put it in that project's task list.\n" +
            "    No:  Put it in the general task list.\n" +
            "\n" +
            "Done.\n" +
            "\n" +
            "### Archive Phase\n" +
            "\n" +
            "1:  Is this important enough to keep?\n" +
            "    Yes:  Proceed.\n" +
            "    No:  Trash it.\n" +
            "\n" +
            "2:  Does it belong in a specific filing system?  (eg: contacts list, tax files...)\n" +
            "    Yes:  Put it there.\n" +
            "    No:  Put it in your engineering notebook.",
        consequences: "Learn it and apply it to every item in your Inbox.",
    };

export default InformationTriage;