import RelatedPattern from "./RelatedPattern";
import SectionName from "./SectionName";

function RelatedPatterns({pattern}) {
    const getRelatedPatterns = () => {
        if (pattern.related_patterns.length > 0) {
            return pattern.related_patterns.map(id => <RelatedPattern related={id}/>)
        } else {
            return "None";
        }
    }

    return (
        <div>
            <SectionName name={"Related Patterns"}/>
            {getRelatedPatterns()}
        </div>
    );
}

export default RelatedPatterns;