import SectionName from "./SectionName";
import RelatedAntiPattern from "./RelatedAntiPattern";

function RelatedAntiPatterns({pattern}) {
    const getRelatedAntipatterns = () => {
        if (pattern.related_antipatterns.length > 0) {
            return pattern.related_antipatterns.map(id => <RelatedAntiPattern related={id}/>)
        } else {
            return "None";
        }
    }

    return (
        <div>
            <SectionName name={"Related AntiPatterns"}/>
            {getRelatedAntipatterns()}
        </div>
    );
}

export default RelatedAntiPatterns;