import React from 'react';
import ReactMarkdown from "react-markdown";
import gfm from 'remark-gfm';

function MarkdownDisplay({children}) {
    return (
        <ReactMarkdown remarkPlugins={[gfm]} className={"prose prose-lg"}>
            {children}
        </ReactMarkdown>
    );
}

export default MarkdownDisplay;