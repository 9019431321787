import {useParams} from "react-router-dom";
import PatternDB from "../data/PatternDB";
import Pattern from "../components/Pattern";

function PatternPage() {
    const params = useParams();
    const pattern = PatternDB.findPatternBySlug(params.slug);

    return (
        <div>
            <Pattern pattern={pattern}/>
        </div>
    );
}

export default PatternPage;