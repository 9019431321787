import React from 'react';
import PatternSection from "./pattern/PatternSection";
import AntiPatternHeader from "./pattern/AntiPatternHeader";
import BetterPatterns from "./pattern/BetterPatterns";
import RelatedAntiPatterns from "./pattern/RelatedAntipatterns";

function Antipattern({pattern}) {
    return (
        <div className={""}>
            <AntiPatternHeader pattern={pattern.content}/>
            <PatternSection name={"Symptoms"} section={pattern.content.symptoms}/>
            <PatternSection name={"Why It Sucks"} section={pattern.content.why_it_sucks}/>
            <BetterPatterns pattern={pattern}/>
            <RelatedAntiPatterns pattern={pattern}/>
        </div>
    );
}

export default Antipattern;