const AmeliaBedelia = {
        name: "Amelia Bedelia",
        blurb: "For when you are being too literal",
        symptoms: "This is a tricky one to spot.  When you're in Amelia Bedelia mode, your brain is being too literal and exposing your blind spots and assumptions.  The best way to spot it is to recognize thoughts like this:\n" +
            "\n" +
            "* Why would they ask that?\n" +
            "* Are they being obtuse, or am I?\n" +
            "* What the hell is going on here?",
        why_it_sucks: "Miscommunications, lost time, hurt feelings...",
    };

export default AmeliaBedelia;