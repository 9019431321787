const SinglePurposePrinciple = {
        name: "Single Purpose Principle (SPP)",
        blurb: "For organizing data or things",
        type: "proactive",
        triggers: "You're not sure where things go, either virtually or physically.",
        solution: "The Single Purpose Principle (SPP) indicates that storage containers and programming constructs should have exactly one job that they do well.",
        implementation: "* The silverware drawer is following the Single Purpose Principle.  The junk drawer is not.",
        consequences: "Sometimes the Single Purpose Principle gets in the way- during [Reasonable Heroics](/pattern/ReasonableHeroics) as an example. " +
            "In crisis times, it's fine to ignore the SPP, but be aware that when you do you're inviting a buildup of [Cruft](/antipattern/Cruft) that will" +
            "just have to be cleaned up later.",
    };

export default SinglePurposePrinciple;