import DescribingEmotions from "./articles/DescribingEmotions";

const articles = [
    { id: 1, slug:"DescribingEmotions", content: DescribingEmotions},

];


const ArticleDB = {
    findArticleById: (id) => {
        return articles.filter( p => p.id === id)[0];
    },


    findArticleBySlug: (slug) => {
        return articles.filter( p => p.slug === slug)[0];
    },
}

export default ArticleDB;
export {articles}