const RecordTheResearch = {
        name: "Record the Research",
        blurb:"For when you've put effort into finding knowledge or a solution",
        type: "proactive",
        triggers: "You just spent more than five minutes researching information or solving a problem.",
        solution: "Record the results in order to prevent spending the same time again later.",
        implementation: "You'll need to have a dedicated place to record your findings.  [The Inbox](/pattern/TheInbox) " +
            "might be a good place to start.",
        consequences: "The importance of a place to consistently record **and refer to** your findings cannot be overstated." +
            "Failing to find a solution for that will just result in your brain not trusting your library and it will fall " +
            "into self-fulfilling-prophetic disuse.",
    };
    
export default RecordTheResearch;