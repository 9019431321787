import RelatedPattern from "./RelatedPattern";
import SectionName from "./SectionName";

function RelatedPatterns({pattern}) {

    const getBetterPatterns = () => {
        if (pattern.better_patterns.length > 0) {
            return pattern.better_patterns.map(id => <RelatedPattern related={id}/>)
        } else {
            return "None";
        }
    }

    return (
        <div>
            <SectionName name={"Better Patterns"}/>
            {getBetterPatterns()}
        </div>
    );
}

export default RelatedPatterns;