import React from 'react';
import PatternHeader from "./pattern/PatternHeader";
import PatternSection from "./pattern/PatternSection";
import RelatedPatterns from "./pattern/RelatedPatterns";
import RelatedAntiPatterns from "./pattern/RelatedAntipatterns";

function Pattern({pattern}) {
    return (
        <div className={""}>
            <PatternHeader pattern={pattern.content}/>
            <PatternSection name={"Triggers"} section={pattern.content.triggers}/>
            <PatternSection name={"Solution"} section={pattern.content.solution}/>
            <PatternSection name={"Implementation"} section={pattern.content.implementation}/>
            <PatternSection name={"Consequences"} section={pattern.content.consequences}/>
            <RelatedPatterns pattern={pattern}/>
            <RelatedAntiPatterns pattern={pattern}/>
        </div>
    );
}

export default Pattern;