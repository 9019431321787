import React from 'react';

function SectionName({name}) {
    return (
        <div className={"text-2xl text-blue-800 font-bold mt-4"}>
            {name}
        </div>
    );
}

export default SectionName;