import Container from "./components/ui/Container";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Pages from "./pages/_pages";
import HomePage from "./pages/HomePage";
import PatternPage from "./pages/PatternPage";
import AntipatternPage from "./pages/AntipatternPage";
import PatternListPage from "./pages/PatternListPage";
import AntipatternListPage from "./pages/AntipatternListPage";
import Navbar from "./components/ui/Navbar";
import Footer from "./components/ui/Footer";
import ArticlePage from "./pages/ArticlePage";
import ArticlesPage from "./pages/ArticlesPage";

function App() {
  return (
      <BrowserRouter>
          <Navbar/>
          <Container>

              <Routes>
                  <Route path={Pages.HOME} element={<HomePage/>}/>
                  <Route path={Pages.PATTERN} element={<PatternPage/>}/>
                  <Route path={Pages.ANTIPATTERN} element={<AntipatternPage/>}/>
                  <Route path={Pages.PATTERNS} element={<PatternListPage/>}/>
                  <Route path={Pages.ANTIPATTERNS} element={<AntipatternListPage/>}/>
                  <Route path={Pages.ARTICLE} element={<ArticlePage/>}/>
                  <Route path={Pages.ARTICLES} element={<ArticlesPage/>}/>
              </Routes>

          </Container>
          <Footer/>
      </BrowserRouter>
  );
}

export default App;
