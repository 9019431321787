import {useParams} from "react-router-dom";
import PatternDB from "../data/PatternDB";
import Antipattern from "../components/Antipattern";


function AntipatternPage() {
    const params = useParams();
    const pattern = PatternDB.findAntiPatternBySlug(params.slug);

    return (
        <div>
            <Antipattern pattern={pattern}/>
        </div>
    );
}

export default AntipatternPage;