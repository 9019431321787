/* This example requires Tailwind CSS v2.0+ */
import Pages from "../../pages/_pages";
import {Link} from "react-router-dom";

const navigation = {
    main: [
        { name: 'Home', href: Pages.HOME },
        { name: 'Patterns', href: Pages.PATTERNS },
        { name: 'Antipatterns', href: Pages.ANTIPATTERNS },
    ],

}

export default function Footer() {
    return (
        <footer className="bg-white border-t border-gray-200 mt-20">
            <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
                <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
                    {navigation.main.map((item) => (
                        <div key={item.name} className="px-5 py-2">
                            <Link to={item.href} className="text-base text-gray-500 hover:text-gray-900">
                                {item.name}
                            </Link>
                        </div>
                    ))}
                </nav>
                <p className="mt-8 text-center text-base text-gray-400">
                    &copy; 2022 Jason Klueber. All rights reserved.
                </p>
                <div className="mt-8 text-center text-base text-gray-400"><a href="https://www.linkedin.com/in/jason-klueber/">Find me on Linked In</a></div>
                <p className="mt-8 text-center text-base text-gray-400">
                    Developed with love for the neurodivergent, the nonstandard deviants, and the wonderful people who care for them
                </p>
            </div>
        </footer>
    )
}
