const NextStep = {
        name: "Next Step",
        blurb:"For multi step projects",
        type: "proactive",
        triggers: "You have a multi step goal/project. Make sure you don't lose ground because your memory isn't the best.",
        solution: "The Next Step is exactly what it sounds like:  it's the next logical thing to do in the workflow.  ",
        implementation: "If you're already keeping up with [Breadcrumbs](/pattern/Breadcrumbs) then Next Step is just placing a bookmark of some kind in with those.  If you're not using [Breadcrumbs](/pattern/Breadcrumbs) for whatever reason, then make a post it note for your monitor, or a TODO comment in your code (possibly even something like //TODO: NEXT STEP - the next step blah blah blah.)  Some kind of note where you'll naturally find it along with your work.",
        consequences: "If you're finding that you have to keep repeating Next Step in an ad hoc fashion, consider implementing [Breadcrumbs](/pattern/Breadcrumbs)",
    };
    
export default NextStep;