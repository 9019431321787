const MartianPersistence =
    {
        name: "Martian Persistence",
            blurb: "For when the problems are just too big",
        type: "reactive",
        triggers: "The problems have gotten too big, but they have to be dealt with without destroying yourself in the process- this isn't the time for a pyrrhic victory.",
        solution: ">\"At some point, everything’s gonna go south and you’re going to say, this is it. This is how I end." +
            "  Now, you can either accept that, or you can get to work. That’s all it is. You just begin. You do" +
            "  the math. You solve one problem and you solve the next one and then the next. And if you solve enough" +
            "  problems, you get to come home.\" --The Martian",
        implementation: "* Take frequent breaks\n" +
            "* Make a plan\n" +
            "* Make [Breadcrumbs](/pattern/Breadcrumbs)\n" +
            "* Keep your eye on the [Next Step](/pattern/NextStep)\n" +
            "* Don't [Be The Batman](../antipattern/BeTheBatman)\n" +
            "* Don't do it all yourself - [Rely On Pack](/pattern/RelyOnPack)",
        consequences: "When the big push is over, make sure to give yourself time to breathe and recover.  Big pushes demand big breaks."
    }
;



export default MartianPersistence;