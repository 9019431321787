const ReasonableHeroics = {
        name: "Reasonable Heroics",
        blurb:"For when it's Go Time",
        type: "reactive",
        triggers: "It's go time.  Failure isn't an option and time/resources are thin.",
        solution: "Execute execute execute.  Even if you're tired.",
        implementation: "* **Find the [Definition Of Done](/pattern/DefinitionOfDone) first if possible.**\n" +
            "* Find the [Next Step](/pattern/NextStep)\n" +
            "* Make copious [Breadcrumbs](/pattern/Breadcrumbs)\n" +
            "* [Rely On Pack](/pattern/RelyOnPack)\n" +
            "* Be prepared to take a break when things stabilize.",
        consequences: "Beware of falling into [Being The Batman](/antipattern/BeTheBatman).  Reasonable Heroics are defined by goal specific, timeboxed, **urgent** conditions:  if this is becoming a pattern, you probably need to rethink things. \n" +
            "\n" +
            "And as always, big pushes demand big breaks.",
    };

export default ReasonableHeroics;