const TryingToRememberEverything = {
        name: "Trying To Remember Everything",
        blurb: "Exactly what it says on the tin.",
        symptoms: "* You have no organizational system for information\n" +
            "* You're constantly forgetting things\n" +
            "* You're constantly in a state of anxiety that you might forget something",
        why_it_sucks: "* ... see above.\n" +
            "* Your brain isn't actually that great at remembering things... and it knows it.  Trying to keep everything in working memory only goes so far- the more active your life, the more complex your tasks, the less effective your brain will be at cataloguing everything.  You need a system.",
    };

export default TryingToRememberEverything;