const DefinitionOfDone = {
        name: "Definition of Done",
        blurb:"For epic tasks and ongoing maintenance",
        type: "proactive",
        triggers: "You're resisting getting started on an eternal task (eg, housework) or one that doesn't fit into a reasonable unit of working time (eg, housework.)",
        solution: "Come up with an end condition to the work you're committing to that does not involve the task being actually complete (since that would be impossible.)",
        implementation: "* \"Clean the Bedroom\" becomes \"fill a bag of trash\" or \"spend 20 minutes tidying the floor around the door and bed.\"\n" +
            "* \"Write this application\" becomes \"work on this specific feature\" possibly with a time limit.",
        consequences: "Don't forget that you still have to keep doing the eternal tasks, or continue working on the large project.  The Definition of Done just means \"Definition of Done for NOW.\"",
    };

export default DefinitionOfDone;