function ArticleHeader({article}) {

    return (
        <div className={"mb-3 border-b border-grey-100 pb-1"}>
            <div>
                <span className={"text-3xl font-bold mr-3"}>{article.title}</span>
            </div>
        </div>
    );
}

export default ArticleHeader;