import MartianPersistence from "./patterns/MartianPersistence";
import BeTheBatman from "./antipatterns/BeTheBatman";
import AskGoodQuestions from "./patterns/AskGoodQuestions";
import AmeliaBedelia from "./antipatterns/AmeliaBedelia";
import LoneWolf from "./antipatterns/LoneWolf";
import RelyOnPack from "./patterns/RelyOnPack";
import TheInbox from "./patterns/TheInbox";
import TryingToRememberEverything from "./antipatterns/TryingToRememberEverything";
import InformationTriage from "./patterns/InformationTriage";
import AskTheExpert from "./patterns/AskTheExpert";
import Breadcrumbs from "./patterns/Breadcrumbs";
import NextStep from "./patterns/NextStep";
import Reacquaint from "./patterns/Reacquaint";
import DefinitionOfDone from "./patterns/DefinitionOfDone";
import MinimumViableProduct from "./patterns/MinimumViableProduct";
import ExchangeBrickForJade from "./patterns/ExchangeBrickForJade";
import JustFiveMinutes from "./patterns/JustFiveMinutes";
import ReasonableHeroics from "./patterns/ReasonableHeroics";
import Cruft from "./antipatterns/Cruft";
import RecordTheResearch from "./patterns/RecordTheResearch";
import SinglePurposePrinciple from "./patterns/SinglePurposePrinciple";

const patterns = [
    { id: 1, slug:"MartianPersistence", content: MartianPersistence, related_antipatterns: [1], related_patterns:[] },
    { id: 2, slug:"AskGoodQuestions", content: AskGoodQuestions, related_antipatterns: [2], related_patterns:[6] },
    { id: 3, slug:"RelyOnPack", content: RelyOnPack, related_antipatterns: [3, 1], related_patterns:[14] },
    { id: 4, slug:"TheInbox", content: TheInbox, related_antipatterns: [4], related_patterns:[5] },
    { id: 5, slug:"InformationTriage", content: InformationTriage, related_antipatterns: [4], related_patterns:[4] },
    { id: 6, slug:"AskTheExpert", content: AskTheExpert, related_antipatterns: [], related_patterns:[2,16] },
    { id: 7, slug:"Breadcrumbs", content: Breadcrumbs, related_antipatterns: [], related_patterns:[8, 9, 14] },
    { id: 8, slug:"NextStep", content: NextStep, related_antipatterns: [], related_patterns:[7, 9, 14] },
    { id: 9, slug:"Reacquaint", content: Reacquaint, related_antipatterns: [], related_patterns:[7, 8] },
    { id: 10, slug:"DefinitionOfDone", content: DefinitionOfDone, related_antipatterns: [], related_patterns:[11,13,14] },
    { id: 11, slug:"MinimumViableProduct", content: MinimumViableProduct, related_antipatterns: [], related_patterns:[10,13] },
    { id: 12, slug:"ExchangeBrickForJade", content: ExchangeBrickForJade, related_antipatterns: [], related_patterns:[] },
    { id: 13, slug:"JustFiveMinutes", content: JustFiveMinutes, related_antipatterns: [], related_patterns:[10,11] },
    { id: 14, slug:"ReasonableHeroics", content: ReasonableHeroics, related_antipatterns: [1], related_patterns:[10,8,7,3] },
    { id: 15, slug:"RecordTheResearch", content: RecordTheResearch, related_antipatterns: [], related_patterns:[] },
    { id: 16, slug:"SinglePurposePrinciple", content: SinglePurposePrinciple, related_antipatterns: [5], related_patterns:[6] },
];

const antipatterns = [
    { id: 1, slug: "BeTheBatman", content: BeTheBatman, better_patterns: [3, 1, 14], related_antipatterns: [3]},
    { id: 2, slug: "AmeliaBedelia", content: AmeliaBedelia, better_patterns: [2], related_antipatterns: []},
    { id: 3, slug: "LoneWolf", content: LoneWolf, better_patterns: [3], related_antipatterns: [1]},
    { id: 4, slug: "TryingToRememberEverything", content: TryingToRememberEverything, better_patterns: [4,5], related_antipatterns: []},
    { id: 5, slug: "Cruft", content: Cruft, better_patterns: [], related_antipatterns: []},
];

const PatternDB = {


    findPatternById: (id) => {
        return patterns.filter( p => p.id === id)[0];
    },

    findAntiPatternById: (id) => {
        return antipatterns.filter( p => p.id === id)[0];
    },

    findPatternBySlug: (slug) => {
        return patterns.filter( p => p.slug === slug)[0];
    },

    findAntiPatternBySlug: (slug) => {
        return antipatterns.filter( p => p.slug === slug)[0];
    },
}

export default PatternDB;
export {patterns, antipatterns}