const MinimumViableProduct = {
        name: "Minimum Viable Product (MVP)",
        blurb:"For when you don't know where to start",
        type: "reactive",
        triggers: "You're stuck.  Everything is too big, and you don't even know where to start.  Overwhelmed might not even begin to cover it.",
        solution: "Pick the smallest useful step forward, the least painful version of the task that you know you can do and is a step in the right direction.",
        implementation: "* A task like \"clean out the basement\" is too large to contemplate.  The MVP might be \"Sort one box of junk into keep, toss, or donate piles.\"\n" +
            "* \"Write a blog system\" is too big.  \"Research a library to render Markdown\" is a more reasonable task.",
        consequences: "This is all about scope and perspective.  At a VERY high level, the MVP for cleaning a house might " +
            "actually be \"clean the basement\", even though that's still too large to be a single task. Don't be afraid to " +
            "tailor your goals to meet the altitude from which you're planning things.",
    };

export default MinimumViableProduct;