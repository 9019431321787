const PATTERN_URL_BASE = "/pattern/";
const ANTIPATTERN_URL_BASE = "/antipattern/";
const ARTICLE_URL_BASE = "/article/";

const Pages = {
    "HOME": "/",
    "PATTERN": PATTERN_URL_BASE + ":slug",
    "ANTIPATTERN": ANTIPATTERN_URL_BASE + ":slug",
    "PATTERNS": "/patterns",
    "ANTIPATTERNS": "/antipatterns",
    "ARTICLES": "/articles",
    "ARTICLE": ARTICLE_URL_BASE + ":slug",
}

export default Pages;
export {PATTERN_URL_BASE, ANTIPATTERN_URL_BASE, ARTICLE_URL_BASE};